// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

@import '~font-awesome/scss/font-awesome';

// Variables
@import 'variables';

@import '~jquery-timepicker/jquery.timepicker.css';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Bootstrap datepicker
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker.css';

@import '../css/app.css';