#file-list {
    display: flex;
    gap: 10px 20px;
    flex-flow: wrap;
}

.datepicker.datepicker-dropdown {
    padding: 15px;
}

[role="navigation"] svg {
    width: 23px;
}

[role="navigation"] .sm\:hidden {
    display: none;
}

[role="navigation"] a {
    text-decoration: none;
}

#fileUploadForm .btn-animate :first-of-type {
    display: inherit !important;
}